import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <p className='title'>
          行加网络惠点餐
        </p>
        <p>网站优化中。。。</p>
        <a
          className="App-link"
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          冀ICP备17004072号-4
        </a>
    </div>
  );
}

export default App;
